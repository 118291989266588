import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { createMarketingFromForm } from "./marketingMapper";
import { WithTypeContaining } from "../../../types/utilityTypes";

export type MarketingState = {
  beenMemberBefore: boolean | null;
  marketingConsentAccepted: boolean;
  dataPrivacyAcknowledgementAccepted: boolean;
  documentationConfirmed: boolean;
  prospectOwnsSignificantSocialMediaAccount: boolean | null;
  socialMediaConsents: boolean[];
};

export type MarketingPayload = {
  beenMemberBefore: boolean;
  marketingConsentAccepted: boolean;
  dataPrivacyAcknowledgementAccepted: boolean;
  documentationConfirmed: boolean;
  prospectOwnsSignificantSocialMediaAccount: boolean | null;
  socialMediaConsents?: boolean[];
};

interface MarketingAction {
  setMarketing: (
    personalInfoValues: WithTypeContaining<MarketingPayload>
  ) => void;
  reset: () => void;
}

const initialState: MarketingState = {
  beenMemberBefore: null,
  marketingConsentAccepted: false,
  dataPrivacyAcknowledgementAccepted: false,
  documentationConfirmed: false,
  prospectOwnsSignificantSocialMediaAccount: null,
  socialMediaConsents: [false, false, false, false, false, false],
};

export const useMarketingStore = create<MarketingState & MarketingAction>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        setMarketing: (personalInfoValues) => {
          set(createMarketingFromForm(personalInfoValues));
        },
        reset: () => set(initialState),
      }),
      {
        name: "marketing-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
