import { FormProvider } from "react-hook-form";
import React, { useState, useTransition } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Spinner } from "../../components/common/Spinner";
import { MobileInput } from "../../components/MobileInput/MobileInput";
import { ShowValidSection } from "../../components/common/ShowValidSection";
import { Button } from "../../components/common/Button";
import { MobilePin } from "./mobilePin/MobilePin";
import { ServerErrorHandler } from "../common/mutations/ServerErrorHandler";
import { useProfileStore } from "./profileStore";
import { useTinsStore } from "../personal-info/tins/tinsStore";
import { useMobileForm } from "./forms/useMobileForm";
import { usePinForm } from "./forms/usePinForm";
import { errorWithCode, ResponseError } from "../common/api/ResponseError";
import { scrollToError } from "../../utils/scrollToError";
import { useRegistrationStore } from "../registration/registrationStore";
import { useVerifyPinMutation } from "./mutations/verifyPinMutation";
import styles from "./PhoneVerification.module.scss";
import { useVerifyPhoneNumberMutation } from "./verifyPhoneNumberMutation";
import { RegistrationEvent, useEventStore } from "../events/eventStore";
import { useShallow } from "zustand/react/shallow";
import { getCountryConfig } from "../../config/countries/countryUtil";

export const PhoneVerification = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const verifyPhoneNumberMutation = useVerifyPhoneNumberMutation();
  const verifyCustomerMutation = useVerifyPinMutation();
  const [isSmsRequestSubmitting, setSmsRequestSubmitting] = useState(false);
  const [isPending, startTransition] = useTransition();
  const publish = useEventStore(useShallow((state) => state.publish));

  const readOnlyRegistration = useRegistrationStore(
    useShallow((state) => state.readOnlyMode)
  );
  const { pinCodeVerified, numberVerified, setMobile } = useProfileStore(
    useShallow((state) => state)
  );

  const {
    mobileForm,
    isValid,
    handleSubmit,
    useDefaultPhoneCountryCodeIfDefined,
  } = useMobileForm();

  const { pinForm } = usePinForm();

  const defaultCountryCode = useDefaultPhoneCountryCodeIfDefined();

  const getCountryCodeIsoCode = () =>
    mobileForm.getValues("countryCode").split("|")[0].substring(1);

  const sendSms = () => {
    verifyPhoneNumberMutation.mutate(
      {
        countryCode: getCountryCodeIsoCode(),
        phoneNumber: mobileForm.getValues("phoneNumber"),
      },
      {
        onSuccess: () => {
          setMobile(
            mobileForm.getValues("countryCode"),
            mobileForm.getValues("phoneNumber")
          );
          setSmsRequestSubmitting(false);
        },
        // TODO needed?
        onError: (error: ResponseError) => {
          mobileForm.setError("phoneNumber", errorWithCode(error));
          scrollToError();
          setSmsRequestSubmitting(false);
        },
      }
    );
  };

  const handleMobileSubmit = () => {
    if (numberVerified && pinCodeVerified) {
      startTransition(() => navigate("/personalinfo"));
      return;
    }

    if (!isSmsRequestSubmitting) {
      setSmsRequestSubmitting(true);
      sendSms();
    }
  };

  const onPinSubmit = (pin: string) => {
    if (verifyCustomerMutation.isPending) {
      return;
    }

    verifyCustomerMutation.mutate(
      {
        phoneNumberCountryCode: getCountryCodeIsoCode(),
        phoneNumberSubscriberNumber: mobileForm.getValues("phoneNumber"),
        verificationCode: pin,
      },
      {
        onSuccess: () => {
          if (
            !getCountryConfig(useProfileStore.getState().country)
              ?.showResidentRadioBox
          ) {
            useTinsStore.getState().setResident(true);
          }
          publish(RegistrationEvent.PinCodeVerified);
          startTransition(() => navigate("/personalinfo"));
        },
        onError: (error: ResponseError) => {
          pinForm.setValue("pin", "");
          pinForm.setError("pin", errorWithCode(error));
          scrollToError();
        },
      }
    );
  };

  const handleKeyDown = (event: { key: string }) => {
    if (event.key === "Enter" && isValid) {
      handleMobileSubmit();
    }
  };

  const onMobileChange = () => {
    pinForm.setValue("pin", "");
  };

  const isSubmitMobileButtonVisible =
    !numberVerified || (numberVerified && pinCodeVerified);

  return (
    <>
      <Spinner
        isShowing={
          verifyCustomerMutation.isPending ||
          verifyPhoneNumberMutation.isPending ||
          isPending
        }
      />
      <FormProvider {...mobileForm}>
        <form
          onSubmit={handleSubmit(handleMobileSubmit)}
          className={styles.mobileForm}
          data-testid="mobile-form"
        >
          <MobileInput
            countryCodeDisabled={
              readOnlyRegistration || Boolean(defaultCountryCode)
            }
            onKeyPressed={handleKeyDown}
            onPhoneChange={onMobileChange}
            phoneNumberDisabled={readOnlyRegistration}
          />
          <ShowValidSection isDataSubmitted={readOnlyRegistration}>
            {isSubmitMobileButtonVisible && (
              <Button
                id="create-profile-button"
                disabled={!isValid || isPending}
                type="submit"
              >
                {t("CONTINUE")}
              </Button>
            )}
          </ShowValidSection>
        </form>
      </FormProvider>
      {numberVerified && !pinCodeVerified && (
        <FormProvider {...pinForm}>
          <MobilePin
            isSubmitDisabled={
              verifyCustomerMutation.isPending ||
              Number(pinForm.getValues("pin")) <= 999
            }
            onResendCode={onMobileChange}
            onSubmit={onPinSubmit}
          />
        </FormProvider>
      )}
      <ServerErrorHandler
        // @ts-expect-error: need to be fixed
        mutations={[verifyCustomerMutation, verifyPhoneNumberMutation]}
      />
    </>
  );
};
