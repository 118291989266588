import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { IdentityConfirmationState } from "../../features/personal-info/tins/tinsStore";
import { AttachmentType } from "../../features/personal-info/attachments/attachmentsStore";
import { defaultCountryConfigFieldLengths } from "./defaults";
import { CountryFormConfiguration } from "../constants";

const CEUTA_AND_MELILLA_POSTAL_CODE_REGEX =
  /^(5100[1-5]|5107[0-1]|51080|5200[1-6]|5207[0-1]|52080)$/;

const SPANISH_ADDRESS_REGEX = /^[\p{Lower}\p{Upper}\s\-,./º:\d]*$/u;
const SPANISH_FOREIGNER_ID_REGEX = "^[a-zA-Z]\\d{7}[a-zA-Z]$";
const SPANISH_TAX_ID_REGEX = "^\\d{8}[a-zA-Z]$";
const SPANISH_TIN_PATTERN = new RegExp(
  `${SPANISH_FOREIGNER_ID_REGEX}|${SPANISH_TAX_ID_REGEX}`
);

export const Spain: CountryFormConfiguration = {
  id: "es-ES",
  forms: {
    personalInfo: {
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "experian",
        bottomDescription: "DELIVERY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("es-ES"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: SPANISH_ADDRESS_REGEX,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "#####",
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
      country: {
        name: "country",
        label: "COUNTRY",
        type: "text",
        defaultValue: "España",
        validation: {
          required: { value: false },
        },
        rule: {
          isDisabled: true,
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.careOfName,
            errorMessage: "HMP_CARE_OF_NAME_SIZE",
          },
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "experian",
        bottomDescription: "DELIVERY_DISCLAIMER",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 50,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("es-ES"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: SPANISH_ADDRESS_REGEX,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 5, errorMessage: "TOO_LONG" },
          minLength: { value: 5, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{5}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "#####",
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 40, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
    },
  },
  addressLength: 50,
  addressPattern: SPANISH_ADDRESS_REGEX,
  cityLength: 40,
  postalCodeLength: 5,
  postalCodeMask: "#####",
  postalCodePattern: /^[0-9]{5}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: defaultCountryConfigFieldLengths.province,
  provinceRequired: true,
  showDeliveryDisclaimer: true,
  defaultCountryValue: "España",
  readOnlyFields: ["country"],
  adultAge: 18,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  footerLogos: [
    {
      src: "logos/afepadi.png",
      alt: "Afepadi logo",
      urlLink: "https://www.afepadi.org/",
    },
    {
      src: "logos/arbitraje.png",
      alt: "Arbitraje logo",
      urlLink:
        "https://www.comunidad.madrid/servicios/consumo/es-funciona-arbitraje-consumo",
    },
    {
      src: "logos/avd.png",
      alt: "AVD logo",
      urlLink: "https://www.avd.es/",
    },
    {
      src: "logos/foodsuplementseurope.png",
      alt: "Food Suplements Europe logo",
    },
    {
      src: "logos/iadsa.png",
      alt: "IADSA logo",
      urlLink: "https://www.iadsa.org/",
    },
    {
      src: "logos/wfdsa.png",
      alt: "WFDSA logo",
      urlLink: "https://www.wfdsa.org/",
    },
    {
      src: "logos/Visa.png",
      alt: "Visa logo",
    },
    {
      src: "logos/Mastercard.png",
      alt: "Mastercard logo",
    },
  ],
  genderFieldRequired: true,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showFirstNameDisclaimer: true,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showGsgBenefits: true,
  showLastNameDisclaimer: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  showSocialMediaStatement: true,
  showTinSectionRightAfterLastName: true,
  showCountryInput: true,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentAcknowledgment: true,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["es"],
  defaultCountryCode: "+34|Spain",
  residentTins: [
    {
      key: "ssn",
      required: true,
      visibleOn: [
        "$identityConfirmationState",
        (identityConfirmationState: IdentityConfirmationState) =>
          identityConfirmationState !== IdentityConfirmationState.FAILED,
      ],
      minLength: 0,
      maxLength: defaultCountryConfigFieldLengths.registrationNumber,
      pattern: SPANISH_TIN_PATTERN,
      showTinDates: false,
      attachments: [
        {
          visibleOn: [
            "postalCode",
            (postalCode: string) =>
              CEUTA_AND_MELILLA_POSTAL_CODE_REGEX.test(postalCode),
          ],
          required: true,
          key: "resident-attachment-0",
          index: 0,
          type: AttachmentType.OTHER,
        },
        {
          visibleOn: [
            "$identityConfirmationState",
            (identityConfirmationState: IdentityConfirmationState) =>
              identityConfirmationState === IdentityConfirmationState.FAILED,
          ],
          required: true,
          key: "resident-attachment-1",
          index: 1,
          type: AttachmentType.OTHER,
        },
      ],
    },
  ],
} as const;
