import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { CountryFormConfiguration } from "../constants";
import {
  defaultCountryConfigFieldLengths,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";

export const Cyprus: CountryFormConfiguration = {
  id: "el-CY",
  forms: {
    personalInfo: {
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("el-CY"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.careOfName,
            errorMessage: "HMP_CARE_OF_NAME_SIZE",
          },
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("el-CY"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
    },
  },
  addressLength: defaultCountryConfigFieldLengths.address,
  cityLength: defaultCountryConfigFieldLengths.city,
  postalCodeLength: defaultCountryConfigFieldLengths.postalCode,
  postalCodeRequired: true,
  postalCodeMask: false,
  postalCodePattern: /^\d{4}$/,
  province: true,
  provinceLength: defaultCountryConfigFieldLengths.province,
  adultAge: 18,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: false,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: true,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  supportedCountryCodes: ["el"],
  defaultCountryCode: "+357|Cyprus",
  residentTins: [
    {
      key: "ssn",
      required: false,
      minLength: 0,
      maxLength: defaultCountryConfigFieldLengths.registrationNumber,
      pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
      showTinDates: false,
    },
  ],
} as const;
