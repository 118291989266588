import {
  PersonalDetailsState,
  usePersonalDetailsStore,
} from "./personalDetailsStore";
import { WithTypeContaining } from "../../../types/utilityTypes";

export const personalDetailsFromStore = (): PersonalDetailsState => ({
  firstName: usePersonalDetailsStore.getState().firstName,
  lastName: usePersonalDetailsStore.getState().lastName,
  mothersMaidenName: usePersonalDetailsStore.getState().mothersMaidenName,
  middleInitial: usePersonalDetailsStore.getState().middleInitial,
  gender: usePersonalDetailsStore.getState().gender,
  spouseFirstName: usePersonalDetailsStore.getState().spouseFirstName,
  spouseMiddleName: usePersonalDetailsStore.getState().spouseMiddleName,
  spouseLastName: usePersonalDetailsStore.getState().spouseLastName,
  dateOfBirth: usePersonalDetailsStore.getState().dateOfBirth,
  placeOfBirthCountry: usePersonalDetailsStore.getState().placeOfBirthCountry,
  placeOfBirthProvince: usePersonalDetailsStore.getState().placeOfBirthProvince,
  placeOfBirthCity: usePersonalDetailsStore.getState().placeOfBirthCity,
});

export const createPersonalDetailsFromForm = ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  placeOfBirthCountry,
  placeOfBirthProvince,
  placeOfBirthCity,
}: WithTypeContaining<PersonalDetailsState>): PersonalDetailsState => ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  placeOfBirthCountry,
  placeOfBirthProvince,
  placeOfBirthCity,
});

export const createPersonalDetailsRequest = ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  placeOfBirthCountry,
  placeOfBirthProvince,
  placeOfBirthCity,
}: WithTypeContaining<PersonalDetailsState>) => ({
  firstName,
  lastName,
  mothersMaidenName,
  middleInitial,
  gender,
  spouseFirstName,
  spouseMiddleName,
  spouseLastName,
  dateOfBirth,
  placeOfBirth: {
    country: placeOfBirthCountry || "",
    city: placeOfBirthCity || "",
    province: placeOfBirthProvince || "",
  },
});
