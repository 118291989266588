import {
  MarketingPayload,
  MarketingState,
  useMarketingStore,
} from "./MarketingStore";
import { WithTypeContaining } from "../../../types/utilityTypes";
import { useProfileStore } from "../../initialRegistration/profileStore";

import { getCountryConfig } from "../../../config/countries/countryUtil";

const isDataPrivacyAcknowledgementAccepted = (country: string) => {
  const isDataPrivacyAcknowledgement = Boolean(
    getCountryConfig(country)?.isDataPrivacyAcknowledgement
  );
  return isDataPrivacyAcknowledgement
    ? {
        dataPrivacyAcknowledgementAccepted:
          useMarketingStore.getState().dataPrivacyAcknowledgementAccepted,
      }
    : {};
};

export const marketingFromStore = () => {
  return {
    beenMemberBefore: useMarketingStore.getState().beenMemberBefore,
    marketingConsentAccepted:
      useMarketingStore.getState().marketingConsentAccepted,
    documentationConfirmed: useMarketingStore.getState().documentationConfirmed,
    prospectOwnsSignificantSocialMediaAccount:
      useMarketingStore.getState().prospectOwnsSignificantSocialMediaAccount,
    socialMediaConsents: useMarketingStore.getState().socialMediaConsents,
    ...isDataPrivacyAcknowledgementAccepted(useProfileStore.getState().country),
  };
};

export const createMarketingFromForm = ({
  beenMemberBefore,
  marketingConsentAccepted,
  dataPrivacyAcknowledgementAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
  socialMediaConsents,
}: WithTypeContaining<MarketingPayload>): MarketingState => ({
  beenMemberBefore,
  marketingConsentAccepted,
  dataPrivacyAcknowledgementAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
  socialMediaConsents: socialMediaConsents || [],
});

export const personalInfoRelatedMarketingFields = ({
  beenMemberBefore,
  marketingConsentAccepted,
  dataPrivacyAcknowledgementAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
}: WithTypeContaining<MarketingPayload>): MarketingPayload => ({
  beenMemberBefore,
  marketingConsentAccepted,
  dataPrivacyAcknowledgementAccepted,
  documentationConfirmed,
  prospectOwnsSignificantSocialMediaAccount,
});
