import { getCountryConfig } from "../../../config/countries/countryUtil";

export interface CountryType {
  isoCode: string;
  name: string;
  phoneCode: string;
}

const supportedCountries: readonly CountryType[] = [
  {
    phoneCode: "+374",
    name: "Armenia",
    isoCode: "am",
  },
  {
    phoneCode: "+43",
    name: "Austria",
    isoCode: "at",
  },
  {
    phoneCode: "+994",
    name: "Azerbaijan",
    isoCode: "az",
  },
  {
    phoneCode: "+375",
    name: "Belarus",
    isoCode: "by",
  },
  {
    phoneCode: "+32",
    name: "Belgium",
    isoCode: "be",
  },
  {
    phoneCode: "+267",
    name: "Botswana",
    isoCode: "bw",
  },
  {
    phoneCode: "+359",
    name: "Bulgaria",
    isoCode: "bg",
  },
  {
    phoneCode: "+357",
    name: "Cyprus",
    isoCode: "cy",
  },
  {
    phoneCode: "+45",
    name: "Denmark",
    isoCode: "dk",
  },
  {
    phoneCode: "+372",
    name: "Estonia",
    isoCode: "ee",
  },
  {
    phoneCode: "+358",
    name: "Finland",
    isoCode: "fi",
  },
  {
    phoneCode: "+33",
    name: "France",
    isoCode: "fr",
  },
  {
    phoneCode: "+995",
    name: "Georgia",
    isoCode: "ge",
  },
  {
    phoneCode: "+49",
    name: "Germany",
    isoCode: "de",
  },
  {
    phoneCode: "+233",
    name: "Ghana",
    isoCode: "gh",
  },
  {
    phoneCode: "+30",
    name: "Greece",
    isoCode: "gr",
  },
  {
    phoneCode: "+36",
    name: "Hungary",
    isoCode: "hu",
  },
  {
    phoneCode: "+91",
    name: "India",
    isoCode: "in",
  },
  {
    phoneCode: "+62",
    name: "Indonesia",
    isoCode: "id",
  },
  {
    phoneCode: "+964",
    name: "Iraq",
    isoCode: "iq",
  },
  {
    phoneCode: "+353",
    name: "Ireland",
    isoCode: "ie",
  },
  {
    phoneCode: "+972",
    name: "Israel",
    isoCode: "il",
  },
  {
    phoneCode: "+7",
    name: "Kazakhstan",
    isoCode: "kz",
  },
  {
    phoneCode: "+996",
    name: "Kyrgyzstan",
    isoCode: "kg",
  },
  {
    phoneCode: "+371",
    name: "Latvia",
    isoCode: "lv",
  },
  {
    phoneCode: "+961",
    name: "Lebanon",
    isoCode: "lb",
  },
  {
    phoneCode: "+266",
    name: "Lesotho",
    isoCode: "ls",
  },
  {
    phoneCode: "+370",
    name: "Lithuania",
    isoCode: "lt",
  },
  {
    phoneCode: "+352",
    name: "Luxembourg",
    isoCode: "lu",
  },
  {
    phoneCode: "+389",
    name: "Macedonia",
    isoCode: "mk",
  },
  {
    phoneCode: "+356",
    name: "Malta",
    isoCode: "mt",
  },
  {
    phoneCode: "+264",
    name: "Namibia",
    isoCode: "na",
  },
  {
    phoneCode: "+31",
    name: "Netherlands",
    isoCode: "nl",
  },
  {
    phoneCode: "+47",
    name: "Norway",
    isoCode: "no",
  },
  {
    phoneCode: "+48",
    name: "Poland",
    isoCode: "pl",
  },
  {
    phoneCode: "+351",
    name: "Portugal",
    isoCode: "pt",
  },
  {
    phoneCode: "+40",
    name: "Romania",
    isoCode: "ro",
  },
  {
    phoneCode: "+7",
    name: "Russia",
    isoCode: "ru",
  },
  {
    phoneCode: "+381",
    name: "Serbia",
    isoCode: "rs",
  },
  {
    phoneCode: "+421",
    name: "Slovakia",
    isoCode: "sk",
  },
  {
    phoneCode: "+27",
    name: "South Africa",
    isoCode: "za",
  },
  {
    phoneCode: "+34",
    name: "Spain",
    isoCode: "es",
  },
  {
    phoneCode: "+39",
    name: "Italy",
    isoCode: "it",
  },
  {
    phoneCode: "+268",
    name: "Swaziland",
    isoCode: "sz",
  },
  {
    phoneCode: "+46",
    name: "Sweden",
    isoCode: "se",
  },
  {
    phoneCode: "+41",
    name: "Switzerland",
    isoCode: "ch",
  },
  {
    phoneCode: "+420",
    name: "The Czech Republic",
    isoCode: "cz",
  },
  {
    phoneCode: "+90",
    name: "Turkey",
    isoCode: "tr",
  },
  {
    phoneCode: "+380",
    name: "Ukraine",
    isoCode: "ua",
  },
  {
    phoneCode: "+44",
    name: "United Kingdom",
    isoCode: "gb",
  },
  {
    phoneCode: "+998",
    name: "Uzbekistan",
    isoCode: "uz",
  },
  {
    phoneCode: "+260",
    name: "Zambia",
    isoCode: "zm",
  },
];

export const getSupportedCountriesBy = (country: string | null) => {
  if (!country) {
    return supportedCountries;
  }
  return supportedCountries?.filter((item) =>
    item.isoCode.includes(
      getCountryConfig(country)?.supportedCountryCodes as string
    )
  );
};
