import { Armenia } from "./hy-AM";
import { Austria } from "./de-AT";
import { Azerbaijan } from "./az-AZ";
import { Belarus } from "./ru-BY";
import { BelgiumFR } from "./fr-BE";
import { BelgiumNL } from "./nl-BE";
import { Botswana } from "./en-BW";
import { Bulgaria } from "./bg-BG";
import { CzechRepublic } from "./cs-CZ";
import { Cyprus } from "./el-CY";
import { Estonia } from "./et-EE";
import { Germany } from "./de-DE";
import { Georgia } from "./ka-GE";
import { Hungary } from "./hu-HU";
import { Indonesia } from "./id-ID";
import { Italy } from "./it-IT";
import { Kyrgyzstan } from "./ru-KG";
import { Latvia } from "./lv-LV";
import { Lebanon } from "./en-LB";
import { Lesotho } from "./en-LS";
import { Lithuania } from "./lt-LT";
import { Malta } from "./en-MT";
import { Namibia } from "./en-NA";
import { Poland } from "./pl-PL";
import { Slovakia } from "./sk-SK";
import { Spain } from "./es-ES";
import { Swaziland } from "./en-SZ";
import { SwitzerlandCH } from "./de-CH";
import { SwitzerlandFR } from "./fr-CH";
import { SwitzerlandIT } from "./it-CH";
import { UK } from "./en-GB";
import { UzbekistanRU } from "./ru-UZ";
import { UzbekistanUZ } from "./uz-UZ";
import { Zambia } from "./en-ZM";
import { CountryFormConfiguration } from "../constants";

const countryConfigMap: { [key: string]: CountryFormConfiguration } = {
  "hy-AM": Armenia,
  "de-AT": Austria,
  "az-AZ": Azerbaijan,
  "ru-BY": Belarus,
  "fr-BE": BelgiumFR,
  "nl-BE": BelgiumNL,
  "en-BW": Botswana,
  "bg-BG": Bulgaria,
  "cs-CZ": CzechRepublic,
  "el-CY": Cyprus,
  "et-EE": Estonia,
  "de-DE": Germany,
  "ka-GE": Georgia,
  "hu-HU": Hungary,
  "id-ID": Indonesia,
  "it-IT": Italy,
  "ru-KG": Kyrgyzstan,
  "lv-LV": Latvia,
  "en-LB": Lebanon,
  "en-LS": Lesotho,
  "lt-LT": Lithuania,
  "en-MT": Malta,
  "en-NA": Namibia,
  "pl-PL": Poland,
  "sk-SK": Slovakia,
  "es-ES": Spain,
  "en-SZ": Swaziland,
  "de-CH": SwitzerlandCH,
  "fr-CH": SwitzerlandFR,
  "it-CH": SwitzerlandIT,
  "en-GB": UK,
  "ru-UZ": UzbekistanRU,
  "uz-UZ": UzbekistanUZ,
  "en-ZM": Zambia,
};

export const getCountryConfig = (
  countryId: string
): CountryFormConfiguration | undefined => {
  return countryConfigMap[countryId];
};
