import { useMutation } from "@tanstack/react-query";
import { ResponseError } from "../../common/api/ResponseError";
import { initialRegistrationApi } from "../../../api/initialRegistration";
import { useProfileStore } from "../profileStore";
import { usePersonalDetailsStore } from "../../personal-info/personalDetails/personalDetailsStore";
import { useSponsorshipStore } from "../sponsorship/sponsorshipStore";
import { useTinsStore } from "../../personal-info/tins/tinsStore";
import { getCountryConfig } from "../../../config/countries/countryUtil";

export interface InitialRegistrationResponse {
  locale: string;
  email: string;
  prospectFirstName?: string;
  prospectLastName?: string;
  sponsorId?: string;
  sponsorFirstName?: string;
  sponsorLastName?: string;
  isPhoneNumberConfirmed: boolean;
}

export const initialRegistrationMutationKey = ["initialRegistration"];
export const useInitialRegistrationMutation = () =>
  useMutation<InitialRegistrationResponse, ResponseError, void>({
    mutationKey: initialRegistrationMutationKey,
    mutationFn: () => initialRegistrationApi.getInitialRegistrationData(),
    onSuccess: async (response) => {
      await useProfileStore
        .getState()
        .setInitialRegistrationData(
          response.locale,
          response.email,
          response.isPhoneNumberConfirmed
        );
      usePersonalDetailsStore
        .getState()
        .setFirstAndLastName(
          response.prospectFirstName,
          response.prospectLastName
        );
      useSponsorshipStore
        .getState()
        .setConfirmedSponsor(
          response.sponsorId,
          response.sponsorFirstName,
          response.sponsorLastName
        );

      if (!getCountryConfig(response.locale)?.showResidentRadioBox) {
        useTinsStore.getState().setResident(true);
      }
    },
    retry: 3,
  });
