import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { defaultCountryConfigFieldLengths } from "./defaults";
import { CountryFormConfiguration } from "../constants";

export const CzechRepublic: CountryFormConfiguration = {
  id: "cs-CZ",
  forms: {
    personalInfo: {
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("cs-CZ"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 6, errorMessage: "TOO_LONG" },
          minLength: { value: 6, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{3}[" "][0-9]{2}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "### ##",
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.careOfName,
            errorMessage: "HMP_CARE_OF_NAME_SIZE",
          },
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: 40,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("cs-CZ"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 30, errorMessage: "TOO_LONG" },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: { value: 6, errorMessage: "TOO_LONG" },
          minLength: { value: 6, errorMessage: "POSTAL_CODE_SIZE" },
          pattern: {
            value: /^[0-9]{3}[" "][0-9]{2}$/,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          mask: "### ##",
        },
      },
    },
  },
  addressLength: 40,
  cityLength: 30,
  postalCodeLength: 6,
  postalCodeMask: "### ##",
  postalCodePattern: /^[0-9]{3}[" "][0-9]{2}$/,
  postalCodeRequired: true,
  province: false,
  provinceLength: defaultCountryConfigFieldLengths.province,
  adultAge: 18,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: false,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: false,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: false,
  showDateOfBirthDisclaimer: true,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  showPaymentDisclaimer: true,
  supportedCountryCodes: ["cz"],
  defaultCountryCode: "+420|The Czech Republic",
  residentTins: [
    {
      key: "Rodne Cislo",
      required: true,
      minLength: 9,
      maxLength: 10,
      pattern: /[0-9]{6}\/[0-9]{3,4}$/,
      showTinDates: false,
    },
  ],
  nonResidentTins: [],
  tinMask: "######/####",
} as const;
