import { getForbiddenWordsForCountry } from "../../utils/forbiddenWordsForCountry";
import { CountryFormConfiguration, DEFAULT_COUNTRY_CODES } from "../constants";
import {
  DEFAULT_ADDRESS_LINE_PATTERN,
  defaultCountryConfigFieldLengths,
  DIGITS_AND_OR_LATIN_LETTERS_REGEX,
} from "./defaults";

export const Hungary: CountryFormConfiguration = {
  id: "hu-HU",
  forms: {
    personalInfo: {
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("hu-HU"),
            errorMessage: "PO_BOX",
          },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
    },
    futurePersonalInfo: {
      placeOfBirth: {
        name: "placeOfBirth",
        label: "PLACE_OF_BIRTH",
        type: "object",
        validation: {
          required: { value: false },
        },
        rule: {
          isHidden: true,
        },
        fields: {
          city: {
            name: "placeOfBirthCity",
            label: "PLACE_OF_BIRTH",
            type: "text",
            defaultValue: "",
            sanitize: true,
            validation: {
              required: { value: true, errorMessage: "REQUIRED" },
              maxLength: { value: 30, errorMessage: "TOO_LONG" },
              pattern: {
                value: /^[a-zA-Z\s]+$/,
                errorMessage: "INVALID_PLACE_OF_BIRTH_PATTERN",
              },
              trim: true,
            },
          },
        },
      },
    },
    shippingAddress: {
      careOfName: {
        name: "careOfName",
        label: "HMP_CARE_OF_NAME",
        sanitize: true,
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.careOfName,
            errorMessage: "HMP_CARE_OF_NAME_SIZE",
          },
        },
      },
      addressLine1: {
        name: "addressLine1",
        label: "ADDRESS",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.address,
            errorMessage: "ADDRESS_SIZE",
          },
          forbiddenWords: {
            value: getForbiddenWordsForCountry("hu-HU"),
            errorMessage: "PO_BOX",
          },
          pattern: {
            value: DEFAULT_ADDRESS_LINE_PATTERN,
            errorMessage: "FORBIDDEN_WORD",
          },
          trim: true,
        },
      },
      province: {
        name: "province",
        label: "PROVINCE",
        type: "text",
        validation: {
          required: { value: false },
          maxLength: {
            value: defaultCountryConfigFieldLengths.province,
            errorMessage: "TOO_LONG",
          },
        },
      },
      city: {
        name: "city",
        label: "CITY",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.city,
            errorMessage: "TOO_LONG",
          },
          trim: true,
        },
      },
      postalCode: {
        name: "postalCode",
        label: "POSTAL_CODE",
        type: "text",
        validation: {
          required: { value: true, errorMessage: "REQUIRED" },
          maxLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "TOO_LONG",
          },
          minLength: {
            value: defaultCountryConfigFieldLengths.postalCode,
            errorMessage: "POSTAL_CODE_SIZE",
          },
          pattern: { value: /^\d{4}$/, errorMessage: "POSTAL_CODE_SIZE" },
        },
      },
    },
  },
  addressLength: defaultCountryConfigFieldLengths.address,
  cityLength: defaultCountryConfigFieldLengths.city,
  postalCodeLength: defaultCountryConfigFieldLengths.postalCode,
  postalCodeMask: false,
  postalCodePattern: /^\d{4}$/,
  postalCodeRequired: true,
  province: true,
  provinceLength: defaultCountryConfigFieldLengths.province,
  adultAge: 18,
  firstNameLength: defaultCountryConfigFieldLengths.firstName,
  genderFieldRequired: false,
  lastNameLength: defaultCountryConfigFieldLengths.lastName,
  lastNameFirst: true,
  middleInitial: false,
  middleInitialLength: defaultCountryConfigFieldLengths.middleInitial,
  middleInitialRequired: false,
  mothersMaidenName: true,
  mothersMaidenNameLength: defaultCountryConfigFieldLengths.mothersMaidenName,
  mothersMaidenNameRequired: true,
  showDateOfBirthDisclaimer: false,
  showDocumentationBox: false,
  showHmpBenefits: true,
  showHmpPickUpSection: false,
  showHmpPriceContent: true,
  showLatinInfo: false,
  showLinkToCookiePolicy: true,
  showLinkToOrderConfirmation: true,
  showPdfContainer: true,
  showResidentRadioBox: false,
  splitDeliveryTotal: false,
  spouseFirstName: false,
  spouseFirstNameLength: defaultCountryConfigFieldLengths.spouseFirstName,
  spouseMiddleName: false,
  spouseMiddleNameLength: defaultCountryConfigFieldLengths.spouseMiddleName,
  spouseLastName: false,
  spouseLastNameLength: defaultCountryConfigFieldLengths.spouseLastName,
  showOrderHandlerInformation: false,
  supportedCountryCodes: DEFAULT_COUNTRY_CODES,
  residentTins: [
    {
      key: "ssnOrPasswordOrDriving",
      required: true,
      minLength: 8,
      maxLength: 9,
      pattern: DIGITS_AND_OR_LATIN_LETTERS_REGEX,
      showTinDates: false,
      documentTypes: [
        { key: "ID_CARD", pattern: /^\d{6}[a-zA-Z]{2}$/g },
        { key: "DRIVING_LICENSE", pattern: /^[a-zA-Z]{2}\d{6}$/g },
        { key: "PASSPORT", pattern: /^[a-zA-Z]{2}\d{7}$/g },
      ],
    },
  ],
} as const;
