import {
  PublicAdministrationState,
  usePublicAdministrationStore,
} from "./publicAdministrationStore";

export const publicAdministrationFromStore = () => {
  return {
    isPublicAdministrationEmployee:
      usePublicAdministrationStore.getState().isPublicAdministrationEmployee,
  };
};

export const publicAdministrationFromForm = ({
  isPublicAdministrationEmployee,
}: PublicAdministrationState): PublicAdministrationState => ({
  isPublicAdministrationEmployee: isPublicAdministrationEmployee,
});

export const publicAdministrationRequestFromForm = ({
  isPublicAdministrationEmployee,
}: PublicAdministrationState) => {
  return {
    publicAdministrationEmploymentStatus: isPublicAdministrationEmployee,
  };
};
