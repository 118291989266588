import yup from "../../yup/yupInterface";
import { TinRule } from "../../../config/model/TinRules";
import { SingleAttachmentSchema } from "../attachments/AttachmentsSchema";
import { IdentityConfirmationState, useTinsStore } from "./tinsStore";
import { getCountryConfig } from "../../../config/countries/countryUtil";

export const registrationNumberPrefix = (isResident: boolean) =>
  isResident ? "registrationNumber" : "registrationNumberNonResident";
const prepareTinNumberSchema = (
  tinRule: TinRule,
  requiredByWhenCondition: boolean = false
) => {
  let tinNumberSchema = yup.string();

  if (requiredByWhenCondition) {
    tinNumberSchema = tinNumberSchema.required("REQUIRED");
  }

  tinNumberSchema = tinNumberSchema.test(
    "identity-confirmation-not-match",
    "IDENTITY_NOT_CONFIRMED",
    () => {
      return (
        useTinsStore.getState().identityConfirmationState !==
        IdentityConfirmationState.NOT_MATCH
      );
    }
  );

  tinNumberSchema = tinNumberSchema.test(
    "tin-length",
    "REGISTRATION_NUMBER_SIZE",
    (value) => {
      if (value) {
        const tinLength = value.replace("/", "").trim().length;
        return tinLength >= tinRule.minLength && tinLength <= tinRule.maxLength;
      }
      return true;
    }
  );

  if (tinRule.pattern) {
    tinNumberSchema = tinNumberSchema.test(
      "tin-pattern",
      "REGISTRATION_NUMBER_INVALID_PATTERN",
      (value) => {
        if (value) {
          return RegExp(tinRule.pattern).test(value.trim());
        }
        return true;
      }
    );
  }

  if (tinRule?.documentTypes?.length) {
    tinNumberSchema = tinNumberSchema.hasCorrectDocumentType(tinRule);
  }

  return tinNumberSchema;
};

const getTinConditions = (tinRule: TinRule) => {
  if (tinRule.visibleOn) {
    const [visibilityDeterminingField] = tinRule.visibleOn;
    return ["documentType", visibilityDeterminingField];
  }
  return ["documentType"];
};

const isDocumentTypeChosen = (documentType: string | null) =>
  Boolean(documentType);

const visibleOnFunctionFulfilled = (
  value: IdentityConfirmationState,
  tinRule: TinRule
) => {
  if (tinRule.visibleOn) {
    const [, visibleRuleFunction] = tinRule.visibleOn;
    return visibleRuleFunction(value);
  }

  return true;
};

const prepareTinObjectSchemaFor = (country: string, isResident: boolean) => {
  const fieldNamesPrefix = registrationNumberPrefix(isResident);
  const countryConfig = getCountryConfig(country);

  const tins = isResident
    ? countryConfig?.residentTins
    : countryConfig?.nonResidentTins;

  const createTin = (tinRule: TinRule, index: number) => {
    return yup.object().shape({
      [`${fieldNamesPrefix}${index}`]: yup.object().when("isResident", {
        is: isResident,
        then: (schema) =>
          schema.shape({
            documentType:
              tinRule?.documentTypes?.length && tinRule.required
                ? yup.string().required("REQUIRED")
                : yup.string().nullable(),
            documentValue: yup.string().when(getTinConditions(tinRule), {
              is: (
                documentType: string | null,
                value: IdentityConfirmationState
              ) => {
                return (
                  (tinRule.required || isDocumentTypeChosen(documentType)) &&
                  visibleOnFunctionFulfilled(value, tinRule)
                );
              },
              then: () => prepareTinNumberSchema(tinRule, true),
              otherwise: () => prepareTinNumberSchema(tinRule),
            }),
            issueDate: tinRule?.showTinDates
              ? yup.string().hasCorrectIssueDate()
              : yup.string(),
            expiryDate: tinRule?.showTinDates
              ? yup.string().hasCorrectExpiryDate()
              : yup.string(),
            additional: yup.boolean(),
          }),
      }),
    });
  };

  // Because of current config in constants.ts where attachments are strongly connected with tins we need to create combined schema here
  const registrationNumbersAndAttachmentsSchema = () => {
    let tinSchema = yup.object().shape({});

    tins?.map((tinRule, documentIndex) => {
      tinSchema = tinSchema.concat(createTin(tinRule, documentIndex));
      tinRule.attachments?.map((attachmentRule, attachmentIndex) => {
        tinSchema = tinSchema.concat(
          SingleAttachmentSchema(
            `${fieldNamesPrefix}${documentIndex}-attachment${attachmentIndex}`,
            attachmentRule,
            isResident
          )
        );
      });
    });

    return tinSchema;
  };

  return registrationNumbersAndAttachmentsSchema();
};

export const TinsSchema = (country: string) => {
  const isResidentSchema = yup.object({
    isResident: yup
      .boolean()
      .nullable()
      // workaround for TS satisfying the type check (null only as initial)
      .test("is-null", "REQUIRED", (value) => value !== null),
  });

  const residentTinsSchema = prepareTinObjectSchemaFor(country, true);
  const nonResidentTinsSchema = prepareTinObjectSchemaFor(country, false);

  return isResidentSchema
    .concat(residentTinsSchema)
    .concat(nonResidentTinsSchema);
};
