import { HomeAddressState, useHomeAddressStore } from "./homeAddressStore";
import { WithTypeContaining } from "../../../types/utilityTypes";

export const homeAddressFromStore = () => {
  return {
    province: useHomeAddressStore.getState().province,
    addressLine1: useHomeAddressStore.getState().addressLine1,
    addressLine2: useHomeAddressStore.getState().addressLine2,
    city: useHomeAddressStore.getState().city,
    postalCode: useHomeAddressStore.getState().postalCode?.toUpperCase(),
    district: useHomeAddressStore.getState().district,
    subdistrict: useHomeAddressStore.getState().subdistrict,
  };
};

export const createHomeAddressFromForm = ({
  province,
  addressLine1,
  addressLine2,
  city,
  postalCode,
  district,
  subdistrict,
}: WithTypeContaining<HomeAddressState>): HomeAddressState => ({
  province,
  addressLine1,
  addressLine2,
  city,
  postalCode: postalCode.toUpperCase(),
  district,
  subdistrict,
});
