import yup from "../../yup/yupInterface";
import { AttachmentRule } from "../../../config/model/TinRules";
import { IdentityConfirmationState, useTinsStore } from "../tins/tinsStore";
import { AttachmentType } from "./attachmentsStore";

import { getCountryConfig } from "../../../config/countries/countryUtil";

export const SPECIAL_UPLOAD_REQUIRED =
  "UPLOAD_REQUIRED_BECAUSE_OF_IDENTITY_CONFIRMATION_FAILURE";

const getAttachmentConditions = (attachmentRule: AttachmentRule) => {
  if (attachmentRule.visibleOn) {
    const [visibilityDeterminingField] = attachmentRule.visibleOn;
    return ["isResident", visibilityDeterminingField];
  }
  return ["isResident"];
};

const getErrorMessageForRequired = () =>
  useTinsStore.getState().identityConfirmationState ===
  IdentityConfirmationState.FAILED
    ? SPECIAL_UPLOAD_REQUIRED
    : "FILE_REQUIRED";

const visibleOnFunctionFulfilled = (
  value: string,
  attachmentRule: AttachmentRule
) => {
  if (attachmentRule.visibleOn) {
    const [, visibleRuleFunction] = attachmentRule.visibleOn;
    // @ts-expect-error: need to be fixed
    return visibleRuleFunction(value);
  }

  return true;
};

export const SingleAttachmentSchema = (
  attachmentFieldName: `${string}attachment${string}`,
  attachmentRule: AttachmentRule,
  isResident: boolean = true
) => {
  return yup.object().shape({
    [attachmentFieldName]: yup
      .object()
      .when(getAttachmentConditions(attachmentRule), {
        is: (isResidentValue: boolean, value: string) => {
          return (
            isResidentValue === isResident &&
            visibleOnFunctionFulfilled(value, attachmentRule)
          );
        },
        then: (schema) =>
          schema.shape({
            file: attachmentRule.required
              ? yup
                  .mixed()
                  .matchesAttachmentExtensionRegex(
                    attachmentRule.acceptedExtensions
                  )
                  .hasCorrectSize()
                  .required(getErrorMessageForRequired())
              : yup
                  .mixed()
                  .matchesAttachmentExtensionRegex(
                    attachmentRule.acceptedExtensions
                  )
                  .hasCorrectSize(),
            awsKey: yup.string(),
            wasUploaded: attachmentRule.required
              ? yup
                  .boolean()
                  .test("was-upload-validation", function validate(value) {
                    const { path, createError } = this;
                    return value
                      ? true
                      : createError({
                          path,
                          message: "FILE_NOT_UPLOADED",
                        });
                  })
              : yup.boolean(),
            type: yup
              .mixed<AttachmentType>()
              .oneOf(Object.values(AttachmentType)),
          }),
      }),
  });
};

export const AttachmentsSchema = (country: string) => {
  const attachmentRules = getCountryConfig(country)?.attachments;
  const allAttachmentsSchemaCombined = attachmentRules?.reduce(
    (schema, attachmentRule) => {
      return schema.concat(
        SingleAttachmentSchema(attachmentRule.key, attachmentRule)
      );
    },
    yup.object().shape({})
  );

  return allAttachmentsSchemaCombined || yup.object();
};
